@font-face {
    font-family: 'DIN-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('HelveticaBlklt'), url("../fonts/DINPro-Regular_13937.ttf") format('truetype');
}

@font-face {
    font-family: 'DIN-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Regular'), url("../fonts/DINPro-Medium_13936.ttf") format('truetype');
}

input {
    font-family: 'DIN-Regular';
}