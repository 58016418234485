[index="1"] {
  height: 20px !important;
  width: 60px !important;
  margin: -8px 0px 0px -7px !important;
  background-color: #e5e5e5;
  border-radius: 8px !important;
}
[index="2"],
[index="3"],
[index="4"] {
  /* height: 75px !important; */
  /* width: 62px !important; */
  /* background-color: #192b5b; */
  /* border: 3px solid white; */
  border-radius: 8px;
  /* filter: drop-shadow(0px 1px 10px #8c8b8b); */
  /* margin: -34px 0px 0px -7px !important; */
}

[aria-checked="false"]:hover {
  border: 1px solid #d01f28;
  .ms-Toggle-thumb {
    background-color: #d01f28;
  }
}
[aria-checked="true"] {
  background-color: #d01f28;
  z-index: 0;
}
[aria-checked="true"]:hover {
  background-color: #d01f28;
  z-index: 0;
}

[index="2"]:before {
  content: url("../images/icons/handle-arrows.svg");
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform: rotate(180deg);
  margin-right: 2px;
}

[index="3"]:before {
  content: url("../images/icons/handle-arrows.svg");
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform: rotate(180deg);
  margin-right: 2px;
}

[index="4"]:before {
  content: url("../images/icons/handle-arrows.svg");
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform: rotate(180deg);
  margin-right: 2px;
}


